export default class MobileHeader {
    constructor(node) {
        this.node = node;
        this.toggleButtonNode = this.node.querySelector('[data-ref="toggle"]');

        this.toggleButtonNode.addEventListener('click', this.handleToggleButtonClick.bind(this));
        window.addEventListener('mq:mobile-nav:closed', this.handleMobileNavClosed.bind(this));
        window.addEventListener('mq:mobile-nav:opened', this.handleMobileNavOpened.bind(this));
    }

    handleMobileNavClosed() {
        this.toggleButtonNode.setAttribute('aria-expanded', 'false');
    }

    handleMobileNavOpened() {
        this.toggleButtonNode.setAttribute('aria-expanded', 'true');
    }

    handleToggleButtonClick() {
        this.node.dispatchEvent(
            new CustomEvent(
                this.toggleButtonNode.getAttribute('aria-expanded') === 'false'
                    ? 'mq:mobile-nav:open'
                    : 'mq:mobile-nav:close',
                { bubbles: true },
            ),
        );
    }
}
