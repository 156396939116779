export default class ArticleGrid {
    constructor(node) {
        this.node = node;
        this.categoryTriggerNodes = this.node.querySelectorAll('[data-ref="category-trigger"]');
        this.itemNodes = this.node.querySelectorAll('[data-ref="item"]');

        this.node.addEventListener('click', this.handleClick.bind(this));
    }

    handleClick(event) {
        if (event.target.closest('[data-ref="category-trigger"]')) {
            this.handleCategoryTriggerClick(event);
        }
    }

    handleCategoryTriggerClick(event) {
        const categoryTriggerNode = event.target.closest('[data-ref="category-trigger"]');
        const { category } = categoryTriggerNode.dataset;
        this.categoryTriggerNodes.forEach((categoryTriggerNode) =>
            categoryTriggerNode.setAttribute(
                'aria-current',
                categoryTriggerNode.dataset.category === category,
            ),
        );
        this.itemNodes.forEach((itemNode) =>
            category === ''
                ? itemNode.removeAttribute('hidden')
                : itemNode.dataset.category === category
                  ? itemNode.removeAttribute('hidden')
                  : itemNode.setAttribute('hidden', 'true'),
        );
    }
}
