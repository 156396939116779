import { supportsPassiveEvents } from 'detect-passive-events';
import { throttle } from 'throttle-debounce';

export default class ImageCarousel {
    constructor(node) {
        this.node = node;
        this.listNode = this.node.querySelector('[data-ref="list"]');
        this.itemNodes = this.node.querySelectorAll('[data-ref="item"]');
        this.previousButtonNode = this.node.querySelector('[data-ref="previous"]');
        this.nextButtonNode = this.node.querySelector('[data-ref="next"]');

        this.listNode.addEventListener('wheel', this.handleListNodeWheel.bind(this));
        this.listNode.addEventListener(
            'scroll',
            throttle(16, this.handleListNodeScroll.bind(this)),
            supportsPassiveEvents ? { passive: true } : false,
        );
        this.previousButtonNode.addEventListener(
            'click',
            this.handlePreviousButtonNodeClick.bind(this),
        );
        this.nextButtonNode.addEventListener('click', this.handleNextButtonNodeClick.bind(this));

        this.handleListNodeScroll();
    }

    handleListNodeScroll() {
        const { clientWidth, scrollLeft, scrollWidth } = this.listNode;
        this.previousButtonNode.disabled = scrollLeft === 0;
        this.nextButtonNode.disabled = scrollLeft === scrollWidth - clientWidth;
    }

    handleListNodeWheel(event) {
        const { clientWidth, clientHeight, scrollLeft, scrollWidth } = this.listNode;
        const { deltaY } = event;

        // Don't redirect scrolling if component is taller than 95% of
        // viewport height
        if (clientHeight > document.documentElement.clientHeight * 0.95) return;

        // Redirect scrolling if list node can be scrolled in the
        // corresponding horizontal direction
        if (
            (deltaY < 0 && scrollLeft > 0) ||
            (deltaY > 0 && scrollLeft < scrollWidth - clientWidth)
        ) {
            event.preventDefault();
            this.listNode.scrollLeft += deltaY;
        }
    }

    handleNextButtonNodeClick() {
        // Find first item node with left edge position greater than list node
        // left edge, then scroll the container by the difference
        const { left: listLeft } = this.listNode.getBoundingClientRect();
        for (const itemNode of [...this.itemNodes]) {
            const { left: itemLeft } = itemNode.getBoundingClientRect();
            if (Math.round(itemLeft) > Math.round(listLeft)) {
                const { scrollLeft } = this.listNode;
                const delta = itemLeft - listLeft;
                this.listNode.scrollTo({
                    left: scrollLeft + delta,
                    behavior: 'smooth',
                });
                break;
            }
        }
    }

    handlePreviousButtonNodeClick() {
        // Iterate item nodes in reverse and find first with left edge
        // position less than list node left edge, then scroll the container
        // by the difference
        const { left: listLeft } = this.listNode.getBoundingClientRect();
        for (const itemNode of [...this.itemNodes].reverse()) {
            const { left: itemLeft } = itemNode.getBoundingClientRect();
            if (Math.round(itemLeft) < Math.round(listLeft)) {
                const { scrollLeft } = this.listNode;
                const delta = listLeft - itemLeft;
                this.listNode.scrollTo({
                    left: scrollLeft - delta,
                    behavior: 'smooth',
                });
                break;
            }
        }
    }
}
