// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
export var mobile = "375";
export var mobilePx = "375px";
export var tablet = "768";
export var tabletPx = "768px";
export var smallDesktop = "1024";
export var smallDesktopPx = "1024px";
export var desktop = "1280";
export var desktopPx = "1280px";
export default ___CSS_LOADER_EXPORT___;
