import { scaleLinear } from 'd3-scale';
import { throttle } from 'throttle-debounce';

import { mobileLayout } from '@lib/media';
import { normalisePointerEvent } from '@lib/pointer';

const TRANSLATION_RANGE = [25, 0, -25];

export default class HomeGrid {
    constructor(node) {
        this.node = node;
        this.listNode = this.node.querySelector('[data-ref="list"]');

        window.addEventListener('mousemove', throttle(100, this.handlePointerMove.bind(this)));

        if ('addEventListener' in mobileLayout) {
            mobileLayout.addEventListener('change', this.handleLayoutChange.bind(this));
        } else {
            mobileLayout.addListener(this.handleLayoutChange.bind(this));
        }
    }

    handleLayoutChange() {
        this.listNode.style.transform = '';
    }

    handlePointerMove(event) {
        if (mobileLayout.matches) return false;

        const pointer = normalisePointerEvent(event);
        const { clientWidth, clientHeight } = document.documentElement;
        const xScale = scaleLinear()
            .domain([0, clientWidth / 2, clientWidth])
            .range(TRANSLATION_RANGE)
            .clamp(true);
        const yScale = scaleLinear()
            .domain([0, clientHeight / 2, clientHeight])
            .range(TRANSLATION_RANGE)
            .clamp(true);
        const translateX = xScale(pointer.x);
        const translateY = yScale(pointer.y);
        this.listNode.style.transform = `translateX(${translateX}%) translateY(${translateY}%)`;
    }
}
