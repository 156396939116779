import { createFocusTrap } from 'focus-trap';

import { mobileLayout } from '@lib/media';

export default class MobileNav {
    constructor(node) {
        this.node = node;
        this.mainNode = document.getElementById('main');

        this.onDocumentKeyUp = this.handleDocumentKeyUp.bind(this);
        window.addEventListener('mq:mobile-nav:close', this.close.bind(this));
        window.addEventListener('mq:mobile-nav:open', this.open.bind(this));

        if ('addEventListener' in mobileLayout) {
            mobileLayout.addEventListener('change', this.handleLayoutChange.bind(this));
        } else {
            mobileLayout.addListener(this.handleLayoutChange.bind(this));
        }

        this.focusTrap = createFocusTrap(
            [
                this.node,
                document.getElementById(this.node.getAttribute('aria-controlledby')).parentNode,
            ],
            {
                allowOutsideClick: true,
            },
        );
    }

    handleDocumentKeyUp(event) {
        if (event.key === 'Escape') this.close();
    }

    handleLayoutChange() {
        this.close();
    }

    close() {
        this.node.setAttribute('hidden', true);
        document.removeEventListener('keyup', this.onDocumentKeyUp);
        document.body.classList.remove('has-open-modal');
        if (this.mainNode) this.mainNode.removeAttribute('aria-hidden', true);
        this.node.dispatchEvent(new CustomEvent('mq:mobile-nav:closed', { bubbles: true }));
        this.focusTrap.deactivate();
    }

    open() {
        this.node.removeAttribute('hidden');
        document.addEventListener('keyup', this.onDocumentKeyUp);
        document.body.classList.add('has-open-modal');
        if (this.mainNode) this.mainNode.setAttribute('aria-hidden', true);
        this.node.dispatchEvent(new CustomEvent('mq:mobile-nav:opened', { bubbles: true }));
        this.focusTrap.activate();
    }
}
