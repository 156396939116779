import { scaleLinear } from 'd3-scale';
import { supportsPassiveEvents } from 'detect-passive-events';
import { throttle } from 'throttle-debounce';

export default class PageHero {
    constructor(node) {
        this.node = node;
        this.textNode = this.node.querySelector('[data-ref="text"]');
        this.titleNode = this.node.querySelector('[data-ref="title"]');
        this.subtitleNode = this.node.querySelector('[data-ref="subtitle"]');

        if (!('ScrollTimeline' in window)) {
            document.addEventListener(
                'scroll',
                throttle(16, this.handleScroll.bind(this)),
                supportsPassiveEvents ? { passive: true } : false,
            );
            this.handleScroll();
        }
    }

    handleScroll() {
        const { clientHeight } = document.documentElement;

        // XXX: Applying blur to the image causes layout jitter, so apply to
        // the text wrapper element instead
        const textBlurScale = scaleLinear()
            .domain([0, clientHeight * 0.25, clientHeight])
            .range([0, 50, 50])
            .clamp(true);
        const textBlur = textBlurScale(window.scrollY);
        this.textNode.style.webkitBackdropFilter = `blur(${textBlur}px)`;
        this.textNode.style.backdropFilter = `blur(${textBlur}px)`;

        const titleTransformScale = scaleLinear()
            .domain([0, clientHeight * 0.25, clientHeight])
            .range([0, -100, -100])
            .clamp(true);
        const titleTransform = titleTransformScale(window.scrollY);
        this.titleNode.style.animation = 'none';
        this.titleNode.style.transform = `translateY(${titleTransform}%)`;

        const titleOpacityScale = scaleLinear()
            .domain([0, clientHeight * 0.25, clientHeight])
            .range([1, 0, 0])
            .clamp(true);
        const titleOpacity = titleOpacityScale(window.scrollY);
        this.titleNode.style.opacity = titleOpacity;

        const subtitleTransformScale = scaleLinear()
            .domain([0, clientHeight * 0.25, clientHeight * 0.75, clientHeight])
            .range([100, 100, 0, 0])
            .clamp(true);
        const subtitleTransform = subtitleTransformScale(window.scrollY);
        this.subtitleNode.style.animation = 'none';
        this.subtitleNode.style.transform = `translateY(${subtitleTransform}%)`;

        const subtitleOpacityScale = scaleLinear()
            .domain([0, clientHeight * 0.25, clientHeight * 0.75, clientHeight])
            .range([0, 0, 1, 1])
            .clamp(true);
        const subtitleOpacity = subtitleOpacityScale(window.scrollY);
        this.subtitleNode.style.opacity = subtitleOpacity;
    }
}
