// Get pointer position from mouse or touch events
export const normalisePointerEvent = (event) => {
    let pointer = { x: 0, y: 0 };
    if (event.type === 'touchmove') {
        const touch = event.touches[0] || event.changedTouches[0];
        pointer.x = touch.clientX;
        pointer.y = touch.clientY;
    } else if (event.type === 'mousemove') {
        pointer.x = event.clientX;
        pointer.y = event.clientY;
    }
    return pointer;
};
