import ArticleGrid from '@components/article-grid';
import CookieBanner from '@components/cookie-banner';
import DesktopHeader from '@components/desktop-header';
import Footer from '@components/footer';
import HomeGrid from '@components/home-grid';
import ImageCarousel from '@components/image-carousel';
import MobileHeader from '@components/mobile-header';
import MobileNav from '@components/mobile-nav';
import PageHero from '@components/page-hero';
import PlaceCard from '@components/place-card';
import PlaceGrid from '@components/place-grid';
import SectionBanner from '@components/section-banner';

const components = {
    'article-grid': ArticleGrid,
    'cookie-banner': CookieBanner,
    'desktop-header': DesktopHeader,
    'footer': Footer,
    'home-grid': HomeGrid,
    'image-carousel': ImageCarousel,
    'mobile-header': MobileHeader,
    'mobile-nav': MobileNav,
    'page-hero': PageHero,
    'place-card': PlaceCard,
    'place-grid': PlaceGrid,
    'section-banner': SectionBanner,
};

const initComponents = (components, rootNode = document.body) => {
    rootNode.querySelectorAll('[data-component]').forEach((node) => {
        node.dataset.component
            .split(/\s+/)
            .filter((componentName) => componentName in components)
            .map((componentName) => new components[componentName](node));
    });
};
initComponents(components);

// Listen for content load event to rebind component behaviour
window.addEventListener('mq:ajax:load', (event) => {
    console.log('Binding components in loaded content', event.target);
    initComponents(components, event.target);
});
