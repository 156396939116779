import lottie from 'lottie-web/build/player/lottie_light';

export default class SectionBanner {
    constructor(node) {
        this.node = node;
        this.wrapperNode = this.node.querySelector('[data-ref="wrapper"]');

        this.animationPath = this.node.dataset.animationPath;

        this.startAnimation();
    }

    startAnimation() {
        lottie.loadAnimation({
            container: this.wrapperNode,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: this.animationPath,
        });
    }
}
