import { supportsPassiveEvents } from 'detect-passive-events';
import { throttle } from 'throttle-debounce';

import { mobileLayout } from '@lib/media';

export default class DesktopHeader {
    constructor(node) {
        this.node = node;
        this.invertColorAtNode = this.node.dataset.invertColorAt
            ? document.querySelector(this.node.dataset.invertColorAt)
            : null;

        document.addEventListener(
            'scroll',
            throttle(16, this.handleScroll.bind(this)),
            supportsPassiveEvents ? { passive: true } : false,
        );
        this.handleScroll();
    }

    handleScroll() {
        if (mobileLayout.matches) return;
        if (this.invertColorAtNode) {
            const { top } = this.invertColorAtNode.getBoundingClientRect();
            this.node.classList.toggle(
                'has-inverted-color',
                top <= document.documentElement.clientHeight / 2,
            );
        }
    }
}
